<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>
        {{ i18n("Result.TestResultManagement") }}
      </span>
    </div>

    <div class="filter-block">
      <div class="filter-field">
        <span class="title"> {{ i18n("Release.QuizNumber") }} </span>
        <input type="type" name="name" v-model="searchForm.SeqNo" />
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.QuizPostName") }}</span>
        <el-select
          style="display: block"
          v-model="searchForm.ReleaseGuid"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Basic.All')" selected></el-option>
          <el-option
            v-for="release in releaseList"
            :key="release.Value"
            :label="release.Text"
            :value="release.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.PersonalityClassification") }}</span>
        <el-select
          style="display: block"
          v-model="searchForm.Categorys"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Basic.All')" selected></el-option>
          <el-option
            v-for="option in categoryOptions"
            :key="option.value"
            :label="option.value"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.TestForInterviewers") }}</span>
        <el-select
          style="display: block"
          v-model="searchForm.IsInterview"
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Basic.All')" selected></el-option>
          <el-option value="是" :label="i18n('Basic.Yes')"></el-option>
          <el-option value="否" :label="i18n('Basic.No')"></el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.CompanyBy") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.CompanyGuid"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
          @change="changeCompany(searchForm.CompanyGuid)"
        >
          <el-option :value="null" :label="i18n('Basic.All')" selected></el-option>
          <el-option
            v-for="company in companyList"
            :key="company.Value"
            :label="company.Text"
            :value="company.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Basic.Department") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.DepartmentGuids"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Basic.All')" selected></el-option>
          <el-option
            v-for="department in departmentList"
            :key="department.Value"
            :label="department.Text"
            :value="department.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.EmployeeNumberOrName") }} </span>
        <input type="type" name="name" v-model="searchForm.EmpKeyword" />
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.JobTitle") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.TitleNames"
          multiple
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option
            v-for="title in titleList"
            :key="title.Value"
            :label="title.Text"
            :value="title.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Basic.Gender") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.Sexs"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Custom.Unlimited')" selected></el-option>
          <el-option
            v-for="sex in sexList"
            :key="sex.Value"
            :label="sex.Text"
            :value="sex.Value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.BachelorOfScience") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.DegreeIds"
          multiple
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option
            v-for="degree in degreeList"
            :key="degree.Value"
            :label="degree.Text"
            :value="degree.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.School") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.SchoolNames"
          multiple
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option
            v-for="school in schoolList"
            :key="school.Value"
            :label="school.Text"
            :value="school.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.BusinessNature") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.NatureGuids"
          multiple
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option
            v-for="nature in natureList"
            :key="nature.Value"
            :label="nature.Text"
            :value="nature.Value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.IsSupervisor") }} </span>
        <el-select
          style="display: block"
          v-model="searchForm.IsManager"
          filterable
          :placeholder="i18n('Basic.PleaseSelect')"
        >
          <el-option :value="null" :label="i18n('Custom.Unlimited')" selected></el-option>
          <el-option :value="true" :label="i18n('Basic.Yes')"></el-option>
          <el-option :value="false" :label="i18n('Basic.No')"></el-option>
        </el-select>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.SeniorityRange") }} </span>
        <div class="flex">
          <input type="type" name="name" v-model="searchForm.WorkAgeMin" />
          -
          <input type="type" name="name" v-model="searchForm.WorkAgeMax" />
        </div>
      </div>

      <div class="filter-field">
        <span class="title"> {{ i18n("Custom.AgeRange") }} </span>
        <div class="flex">
          <input type="type" name="name" v-model="searchForm.AgeMin" />
          -
          <input type="type" name="name" v-model="searchForm.AgeMax" />
        </div>
      </div>

      <div class="filter-btn-wrap">
        <a class="btn btn-yellow" @click.prevent="getDataList">{{
          i18n("Basic.Search")
        }}</a>
      </div>
    </div>

    <!-- <div class="text-right mb-2">
      <a class="btn btn-blue" @click.prevent="openReleaseModal(true)">新增</a>
    </div> -->

    <div class="d-flex justify-content-end mb-2">
      <button
        v-if="dataList.length > 0 && tempSerchForm.ReleaseGuid"
        class="btn btn-sm btn-info mr-2"
        @click.prevent="exportData"
      >
        {{ i18n("Basic.ExportExcel") }}
      </button>
    </div>

    <vue-bootstrap4-table
      :rows="dataList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="finishTime" slot-scope="props">
        <span v-if="props.row.FinishTime">
          {{ dayjs(props.row.FinishTime).format("YYYY-MM-DD HH:mm") }}
        </span>

      </template>
      <template slot="func" slot-scope="props">
        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openDetailModal(props.row)"
        >
          {{ i18n("Basic.View") }}
        </button>

        <button
          class="btn btn-sm btn-blue mr-2"
          @click.prevent="openPatternModal(props.row)"
        >
          {{ i18n("Custom.ViewCorrespondingTemplate") }}
        </button>
      </template>
    </vue-bootstrap4-table>

    <!-- Release modal -->
    <div class="modal fade" id="detailModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <el-form
          :model="detailModal"
          label-position="top"
          ref="detailModal"
          class="modal-content"
        >
          <div class="modal-header">{{ i18n("Result.TestResult") }}</div>
          <div class="modal-body">
            <table class="info-table">
              <tr>
                <th>{{ i18n("Custom.CompanyName") }}</th>
                <td>{{ detailModal.CompanyName }}</td>
                <th>{{ i18n("Basic.Department") }}</th>
                <td>{{ detailModal.DepartmentName }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.JobNumber") }}</th>
                <td>{{ detailModal.EmpNo }}</td>
                <th>{{ i18n("Release.EmployeeName") }}</th>
                <td>{{ detailModal.EmpCName }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.QuizPostName") }}</th>
                <td>{{ detailModal.ReleaseName }}</td>
                <th>{{ i18n("Custom.QuizDate") }}</th>
                <td>{{ detailModal.FinishTime }}</td>
              </tr>
              <tr>
                <th>{{ i18n("Custom.JobTitle") }}</th>
                <td colspan="3">{{ detailModal.TitleName }}</td>
              </tr>
            </table>

            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th style="vertical-align: middle; width: 70px; text-align: center">
                    {{ i18n("Custom.QuestionNumber") }}
                  </th>
                  <th style="vertical-align: middle">{{ i18n("Custom.Topic") }}</th>
                  <th style="vertical-align: middle">
                    {{ i18n("Custom.SubjectOptions") }}
                  </th>
                  <th style="vertical-align: middle">
                    {{ i18n("Custom.CorrespondingPersonalityTraits") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="Question in detailModal.ResultAnswers" :key="Question.SeqNo">
                  <td style="text-align: center">{{ Question.SeqNo }}</td>
                  <td>{{ Question.Question }}</td>
                  <td>{{ Question.Option }}</td>
                  <td style="text-align: center">{{ Question.Personality }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ i18n("Basic.Close") }}
            </button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 對應模板 modal -->
    <el-dialog
      :title="i18n('Custom.CorrespondingTemplate')"
      :visible.sync="dialogVisible"
      width="80%"
      top="40px"
    >
      <TraitComponent :patternModal="patternModal" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ i18n("Basic.Close") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TraitComponent from "@/components/Result/TraitComponent";
import $ from "jquery";

export default {
  data() {
    return {
      dialogVisible: false,
      screen: null, // loading遮罩
      detailModal: {},
      patternModal: {}, //匹配模板modal
      config: {
        card_mode: false,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
        per_page_options: [10, 25, 50],
        global_search: {
          visibility: false,
        },
        // checkbox_rows: true,
      },
      dataList: [],
      releaseList: [],
      companyList: [],
      departmentList: [],
      titleList: [],
      sexList: [],
      degreeList: [],
      schoolList: [],
      natureList: [],
      searchForm: {
        Categorys: null,
        IsInterview: null,
        Sexs: null,
        DepartmentGuids: null,
      },
      tempSerchForm: {},
      classes: {
        table: "table-light table-striped",
        // via: 'table-danger'
      },

      //分類
      categoryOptions: [],
      params: [],
    };
  },
  components: {
    TraitComponent,
  },
  computed: {
    columns() {
      const col = [
        {
          label: this.i18n("Custom.CompanyName"),
          name: "CompanyName",
          sort: true,
        },
        {
          label: this.i18n("Basic.Department"),
          name: "DepartmentName",
          sort: true,
        },
        {
          label: this.i18n("Custom.JobNumber"),
          name: "EmpNo",
          sort: true,
        },
        {
          label: this.i18n("Release.EmployeeName"),
          name: "EmpCName",
          sort: true,
        },
        {
          label: this.i18n("Custom.PersonalityClassification"),
          name: "Category",
          sort: true,
        },
        {
          label: this.i18n("Custom.JobTitle"),
          name: "TitleName",
          sort: true,
        },
        {
          label: this.i18n("Release.QuizNumber"),
          name: "SeqNo",
          sort: true,
        },
        {
          label: this.i18n("Custom.QuizPostName"),
          name: "ReleaseName",
          sort: true,
        },
        {
          label: this.i18n("Custom.FinishTime"),
          name: "FinishTime",
          sort: true,
          slot_name: "finishTime",
        },
        {
          label: this.i18n("Custom.Function"),
          name: "func",
          slot_name: "func",
        },
      ];
      return col;
    },
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getDataList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result`;
      const params = { ...this.searchForm };
      this.tempSerchForm = { ...this.searchForm };
      this.$http
        .get(url, { params })
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.dataList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getReleaseList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Release`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.releaseList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    getCompanyList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Company`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.companyList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    changeCompany(guid) {
      this.searchForm.DepartmentGuids = null;
      if (guid) {
        this.screen = this.openLoading();
        const url = `${window.BaseUrl.api}/Eva/Result/Options/Department/${guid}`;
        this.$http
          .get(url)
          .then((response) => {
            if (response.data.ErrorMessage) {
              this.$notify({
                title: "錯誤",
                message: response.data.ErrorMessage,
                type: "error",
                duration: 0,
              });
            } else {
              this.departmentList = response.data.Data;
            }
            this.screen.close();
          })
          .catch((error) => {
            this.$notify({
              title: "錯誤",
              message: error.response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
            this.screen.close();
          });
      } else {
        this.departmentList = null;
      }
    },

    getDepartmentList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Department/${this.searchForm.CompanyGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.departmentList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    getTitleList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Title`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.titleList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    getSexList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Sex`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.sexList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getDegreeList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Degree`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.degreeList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getSchoolList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/School`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.schoolList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    getNatureList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Options/Nature`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.natureList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    openDetailModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.detailModal = {
              ...response.data.Data,
            };
            $("#detailModal").modal("show");
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 檢視對應模板
    openPatternModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Pattern/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.patternModal = {
              ...response.data.Data,
            };
            this.dialogVisible = true;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
    // 匯出資料
    exportData() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Eva/Result/Excel`;
      vm.screen = this.openLoading();
      const params = { ...this.searchForm };
      this.$http
        .get(api, { params, responseType: "blob" })
        .then((response) => {
          const filename = decodeURIComponent(
            decodeURIComponent(
              response.headers["content-disposition"].split("filename*=UTF-8''")[1]
            )
          );
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    //取得分類
    getCategoryOptions() {
      this.screen = this.openLoading();
      this.params = [];
      const url = `${window.BaseUrl.api}/system/config`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let arr = [];
            arr = Object.keys(response.data.Data);

            for (let i = 1; i < arr.length; ++i) {
              this.params.push({
                title: arr[i],
                content: response.data.Data[arr[i]],
              });
            }
          }

          let modal = this.params.find((item) => {
            return item.title === "人格特質模板維護";
          });

          let category = modal.content.find((item) => {
            return item.ConfigKey === "ModuleCategoty";
          });

          this.categoryOptions = category.Value.split("、").map((item) => {
            return {
              value: item,
            };
          });

          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });
    },
  },
  created() {
    this.getReleaseList();
    this.getCompanyList();
    this.getTitleList();
    this.getSexList();
    this.getDegreeList();
    this.getSchoolList();
    this.getNatureList();
    this.getCategoryOptions();
  },
};
</script>
